.successWrapper {
    background-color: #2752a073;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 9;
    left: 0;
}

.successCard {
    width: auto;
    height: auto;
    background-color: white;
    border-radius: 12px;
}