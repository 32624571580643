.justify{
    text-align: justify;
}
.footer{
    background-color: var(--secondary);
}
.footer div{
    max-width: 1200px;
    color: #fff;
    margin: 0 auto;
    padding: 70px 30px;
    text-align: justify;
    bottom: 0px;
    /*position: sticky;*/
}
.header{
    box-shadow: 1px 1px 1px 1px #f7f7f7;
    padding: 20px;
    position: sticky;
    z-index: 9;
    top: 0;
    background-color: #ffffff;
}

.passwordValid{
    box-shadow: 8px 8px 1px 0px var(--secondary);
    padding: 20px;
    border: 1px solid var(--secondary);
}
.valid,.invalid{
    margin-bottom: 0px;
}
.w_100{
    width: 100%;
}
.ml_0{
    margin-left: 0px !important;
}
.dropbtn{
    display: flex;
    justify-content: space-between;
}
.text_center{
    text-align: center;
}
.mt_100{
    margin-top: 100px;
}
.loaders, #global-loader1 {
    position: fixed;
    top: 50%; /* Center the loader vertically */
    left: 50%; /* Center the loader horizontally */
    transform: translate(-50%, -50%); /* Center the loader perfectly */
    z-index: 99;
    width: 100%;
    height: 100%; /* Make the loader cover the entire viewport */
    background: #2752a073; /* Semi-transparent background */
}

.loaders img, #global-loader1 img {
    position: absolute; /* Position the loader image absolutely */
    top: 50%; /* Center the image vertically */
    left: 50%; /* Center the image horizontally */
    width: 100px;
    transform: translate(-50%, -50%); /* Center the image perfectly */
    border-radius: 15px;
}

#w3review {
    width: 100%;
    margin-bottom: 8px;
}

.css-1nmdiq5-menu {
    z-index: 4 !important;
}